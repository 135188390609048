.container {
  position: relative;
  display: inline-block;
  background: black;
  color: white;
  padding: 7px 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  height: 35px;
}

.drop_container {
  position: absolute;
  top: 110%;
  right: 0;
  width: fit-content;
  background: white;
  color: black;
  border-radius: 5px;
  /* display: none; */
  z-index: 1;
  list-style: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 110;
}

.drop_container li {
  padding: 7px 20px;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.drop_container li:hover {
  background: rgba(100, 100, 111, 0.2);
}

.drop_container li {
  border-bottom: 1px solid #ccc;
}

.cover {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: transparent;
  top: 0;
  left: 0;
  z-index: 100;
}
