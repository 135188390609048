.button_container {
  height: 35px;
  width: fit-content;
  padding: 0 10px;
  border: none;
  background: none;
  cursor: pointer;
  border-radius: 5px;
  background: transparent;
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
}

.filled {
  background: #1e1e1e;
  color: white;
}

.filled_red {
  background: #ff3333;
  color: white;
}

.filled_green {
  background: #5cb85c;
  color: white;
}

.outlined {
  border: 2px solid black;
  color: black;
}

.outlined_del {
  border: 2px solid #cb1829;
  color: #cb1829;
}

.outlined_success {
  /* success green color */
  border: 2px solid rgb(76, 175, 80);
  color: #4caf50;
}

.light_grey {
  background: rgb(225, 225, 225);
}
.input_filed {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  border: none;
  padding: 5px 10px;
  font-size: 1rem;
}

.table_container {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  overflow: auto;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.table {
  width: 100%;
  border-radius: 10px;
  border-collapse: collapse;
}

.table thead {
  height: fit-content;
  background: lightgrey;
  position: sticky;
  top: 0;
  max-height: 50px !important;
}

.table th {
  padding: 15px 0;
}

.table td {
  padding: 10px 0;
}

.table tr:hover td {
  background: rgba(99, 99, 99, 0.1);
  cursor: pointer;
}

.table tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
