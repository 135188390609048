.content_main {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.content_container {
	width: 100%;
	display: flex;
	align-items: center;
	background: #ececec;
	padding: 5px 15px;
}

.content_container h1 {
	font-size: 1.5rem;
	font-weight: 500;
	text-transform: uppercase;
	letter-spacing: 0.08rem;
}

.content_body_container {
	width: 100%;
	flex-grow: 1;
	overflow: auto;
	padding: 10px 15px;
}
