.button_container {
  height: 40px;
  width: fit-content;
  padding: 0 10px;
  border: none;
  background: none;
  cursor: pointer;
  border-radius: 5px;
  background: transparent;
  font-size: 1rem;
}

.filled {
  background: black;
  color: white;
}

.outlined {
  border: 1px solid black;
  color: black;
}

.input_filed {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  border: none;
  padding: 5px 10px;
  font-size: 1rem;
}

.table_container {
  height: 100%;
  /* width: calc(100vw - 110px); */
  width: 100%;
  /* border-radius: 10px; */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  overflow: auto;
}

.table {
  width: 100%;
  border-radius: 10px;
  border-collapse: collapse;
}

.table th {
  text-align: center;
  position: sticky;
  top: 0;
  background: #d8d8d8;
  font-weight: 500;
  white-space: nowrap;
}

.table th,
.table td {
  padding: 1rem;
}

.table th {
  font-size: 0.8rem;
}

.table td {
  font-weight: 300;
  font-size: 0.8rem;
}

.table thead {
  height: fit-content;
  background: lightgrey;
}

.table tr:hover td {
  background: rgba(99, 99, 99, 0.1);
  cursor: pointer;
}

.table tr td {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.remarks {
  width: fit-content;
  position: relative;
  cursor: pointer;
}

.remarks::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: black;
  bottom: 0;
  left: 0;
  transition: 0.5s;
}

.remarks:hover::after {
  background: rgba(0, 0, 0, 0.2);
  height: 50%;
}
