.container {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 33vw 1fr;
}

.login_nav_container {
  height: fit-content;
  display: flex;
  justify-content: center;
  padding: 40px 0 0 0;
}

.logo {
  z-index: -1;
  background-image: url("");
  background-size: cover;
  background-position: center;
  height: 100px;
  aspect-ratio: 1/1;
}

.main {
  width: 33vw;
  overflow: hidden;
  position: relative;
}

.slide {
  width: fit-content;
  display: flex;
  transition: 0.5s;
}

.forward {
  transform: translateX(-50%);
}

.modal_container {
  height: calc(100vh);
  width: 33vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #202020;
  flex-shrink: 0;
}

.modal_container_2 {
  height: 100vh;
  width: 33vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #252525;
  flex-shrink: 0;
}

.modal {
  height: 400px;
  width: 600px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: transparent;
  transform: translateY(0%);
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}

.modal h1 {
  font-weight: 400;
  padding: 10px 20px;
  background: transparent;
  color: #fff;
  text-align: center;
}

.fields_container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding-top: 30px;
}

.fields_container > button {
  width: 200px;
  margin: 0 auto;
  height: 45px;
  border-radius: 5px;
  border: none;
  margin-top: 10px;
  background: #000;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
}

.fields_container > button:hover {
  background: #000;
}

.label_and_field {
  width: 100%;
  padding: 0 60px;
  color: #fff;
}

.label_and_field span {
  display: block;
  margin-bottom: 5px;
}

.label_and_field input {
  height: 45px;
  width: 100%;
  border-radius: 5px;
  border: none;
  background: rgba(173, 216, 230, 0.2);
  padding: 0 10px;
  color: #fff;
}

.label_and_field input:focus {
  outline: none;
}

.label_and_field button {
  margin-top: 30px;
  width: 100%;
  margin-left: 0%;
  height: 45px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.arrow_container {
  position: absolute;
  top: 20px;
  left: 40px;
  overflow: hidden;
  width: 1.5rem;
}

.back_arrow {
  width: fit-content;
  border-radius: 10px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin-left: 1.5rem;
  transition: 0.5s;
  cursor: pointer;
}

.show_arrow {
  margin-left: 0;
}

.loader {
  height: 60%;
  aspect-ratio: 1/1;
  border: 2px solid #202020;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-left: auto;
  margin-right: auto;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 700px) {
  .container {
    grid-template-columns: 1fr;
    height: 100vh;
  }

  .main,
  .modal_container,
  .modal_container_2 {
    width: 100vw;
  }

  .carousel {
    display: none;
  }
}
