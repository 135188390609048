@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --blue-dark: #36619b;
  --blue-light: #eff5fc;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ebebeb;
}

code {
  font-family: "Roboto", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

select,
select option {
  text-transform: capitalize !important;
}

.ql-toolbar.ql-snow {
  border: none;
  /* border-bottom: 1px solid grey; */
  border-radius: 15px 15px 0 0;
  background: #b9b9b9;
  border-width: 1px !important;
  border-color: transparent !important;
}

.ql-container.ql-snow {
  border-radius: 0 0 15px 15px;
  min-height: 300px;
  border-width: 1px !important;
  border-color: transparent !important;
  /* background-color: rgb(211, 211, 211); */
  border-left: 1px solid grey !important;
  border-right: 1px solid grey !important;
  border-bottom: 1px solid grey !important;
}

.loader {
  width: 40px;
  height: 40px;
  border: 3.5px solid #1e1e1e;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

textarea {
  background-color: white !important;
  border: 1px solid lightgrey !important;
  /* stop expanding in horizontally */
  resize: vertical;
}

textarea:focus {
  outline: 1px solid black !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}

.card {
  background: white;
  border-radius: 20px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.card h3 {
  font-size: 1.3rem;
  font-weight: 600;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.grid_2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.grid_3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.grid_4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
